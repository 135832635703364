import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
import Mares from "../components/mares";
import Layout from "../components/layout";
import './horsegrid.css';

import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { convertToBgImage, BgImage } from "gbimage-bridge"
import BackgroundImage from 'gatsby-background-image'



function SportHorse ({data}) {
  const horseArrSport = data.allMarkdownRemark.edges.map(horses =>{
    return horses.node
  })
  return (
    <>
      <Layout>
        <h1 className="page-title"> SPORT HORSES </h1>
        <div className="references-cards">
          {horseArrSport.map((horse) => {
            const image = getImage(horse.frontmatter.img);
            const bgImage = convertToBgImage(image)

            return(
              <>
              <div id="horse-card-two">
                <Link to={`/horse/${horse.fields.slug}/`}>
                  <BgImage
                    image={image}
                    style={{minWidth: 105,
                     maxHeight:505,
                     backgroundPosition: "top",
                      backgrounSize:"contain"}}
                    id="horse-background"
                  >
                    <Mares
                      name={horse.frontmatter.title}
                      birth={horse.frontmatter.birth}
                      studbook={horse.frontmatter.studbook}
                      gender={horse.frontmatter.gender}
                      color={horse.frontmatter.color}
                    />
                  </BgImage>
                </Link>
              </div>
              </>
            )
          })}
        </div>

      </Layout>
    </>
    );
};

export default SportHorse;

export const AllSportQuery = graphql`
query Sportquery {
  allMarkdownRemark(filter: {frontmatter: {class: {eq: "SPORT"}}}) {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          title
          class
          birth
          gender
          studbook
          color
          img {
            childImageSharp{
              gatsbyImageData(layout: FIXED)
            }
          }
        }
      }
    }
  }
}
`

